import validate from "/builds/trevo/web/node_modules/.pnpm/nuxt@3.13.2_@parcel+watcher@2.4.1_@types+node@22.7.4_eslint@9.11.1_jiti@2.0.0__ioredis@5.4.1__lfhx5gp2j77ibsanr2c6iqulfa/node_modules/nuxt/dist/pages/runtime/validate.js";
import auth_45global from "/builds/trevo/web/middleware/auth.global.ts";
import redirect_45old_45routes_45global from "/builds/trevo/web/middleware/redirectOldRoutes.global.ts";
import manifest_45route_45rule from "/builds/trevo/web/node_modules/.pnpm/nuxt@3.13.2_@parcel+watcher@2.4.1_@types+node@22.7.4_eslint@9.11.1_jiti@2.0.0__ioredis@5.4.1__lfhx5gp2j77ibsanr2c6iqulfa/node_modules/nuxt/dist/app/middleware/manifest-route-rule.js";
export const globalMiddleware = [
  validate,
  auth_45global,
  redirect_45old_45routes_45global,
  manifest_45route_45rule
]
export const namedMiddleware = {}