<script setup lang="ts">
defineProps({
  modelValue: {
    type: String,
    required: true,
  },
  validation: {
    type: Object,
    default: () => ({ $errors: [] }),
  },
})

defineEmits(['update:modelValue', 'change'])

const mask = '+55 (##) 9####-####'
const size = mask.length
</script>

<template>
  <TInputText
    id="input-phone"
    :model-value="modelValue"
    label="telefone"
    inputmode="tel"
    :mask="mask"
    :max="size"
    :min="size"
    :validation="validation"
    @update:model-value="$emit('update:modelValue', $event)"
    @change="$emit('change', $event)"
  />
</template>
