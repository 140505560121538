export enum PromoError {
  NOT_FOUND = 'E17001',
  MIN_VALUE_NOT_REACHED = 'E17007',
  CANNOT_USE_COUPON = 'E17005',
}

export interface PromoErrorInfo {
  message: string
  formFields: string[]
}

export type PromoErrorMessageType = {
  [key in PromoError]: PromoErrorInfo
}

export const PromoErrorMessage: PromoErrorMessageType = {
  [PromoError.NOT_FOUND]: {
    message: 'Cupom não encontrado.',
    formFields: ['promoSearch'],
  },
  [PromoError.MIN_VALUE_NOT_REACHED]: {
    message: 'Valor mínimo para uso do cupom não atingido.',
    formFields: ['promoSearch'],
  },
  [PromoError.CANNOT_USE_COUPON]: {
    message: 'Este cupom não é válido para esta compra.',
    formFields: ['promoSearch'],
  },
}
