import { default as _91categoria_93BjjxpN4tkfMeta } from "/builds/trevo/web/pages/[location]/categorias/[categoria].vue?macro=true";
import { default as _91slug_9374PyWByEg7Meta } from "/builds/trevo/web/pages/[location]/exames/[slug].vue?macro=true";
import { default as index5G8hc1Om63Meta } from "/builds/trevo/web/pages/[location]/exames/index.vue?macro=true";
import { default as indexrEGbFREauOMeta } from "/builds/trevo/web/pages/[location]/index.vue?macro=true";
import { default as confirmacao_45de_45agendamentoR0DT4cNrOSMeta } from "/builds/trevo/web/pages/checkout/confirmacao-de-agendamento.vue?macro=true";
import { default as endereco_45de_45cobrancaW14lAxbet3Meta } from "/builds/trevo/web/pages/checkout/endereco-de-cobranca.vue?macro=true";
import { default as forma_45de_45pagamentovClpeXTunkMeta } from "/builds/trevo/web/pages/checkout/forma-de-pagamento.vue?macro=true";
import { default as indexko2mxm5Ey8Meta } from "/builds/trevo/web/pages/checkout/index.vue?macro=true";
import { default as pagamento_45aprovadojSDq6TsWmfMeta } from "/builds/trevo/web/pages/checkout/pagamento-aprovado.vue?macro=true";
import { default as pagamentoV8B2PDPkknMeta } from "/builds/trevo/web/pages/checkout/pagamento.vue?macro=true";
import { default as pedido_45medicoB3HrMkLrKtMeta } from "/builds/trevo/web/pages/checkout/pedido-medico.vue?macro=true";
import { default as pedido_45realizado6Z8XoGmNcUMeta } from "/builds/trevo/web/pages/checkout/pedido-realizado.vue?macro=true";
import { default as processando_45pagamentorQJ1m4YK0WMeta } from "/builds/trevo/web/pages/checkout/processando-pagamento.vue?macro=true";
import { default as qr_45codePDwCvr7HzeMeta } from "/builds/trevo/web/pages/checkout/qr-code.vue?macro=true";
import { default as revisao_45de_45dadosRRyuXl2sFJMeta } from "/builds/trevo/web/pages/checkout/revisao-de-dados.vue?macro=true";
import { default as compra_45garantidauKi63pLJEPMeta } from "/builds/trevo/web/pages/compra-garantida.vue?macro=true";
import { default as faqarItF7PKASMeta } from "/builds/trevo/web/pages/faq.vue?macro=true";
import { default as indexbSkNw1J8wtMeta } from "/builds/trevo/web/pages/index.vue?macro=true";
import { default as pagamento_45protegidoTv5dGJqxWnMeta } from "/builds/trevo/web/pages/pagamento-protegido.vue?macro=true";
import { default as dados_45cadastraisaCdUv7QsfCMeta } from "/builds/trevo/web/pages/perfil/dados-cadastrais.vue?macro=true";
import { default as historico_45de_45examesnvIiVUA5n8Meta } from "/builds/trevo/web/pages/perfil/historico-de-exames.vue?macro=true";
import { default as indexB170U3CwOUMeta } from "/builds/trevo/web/pages/perfil/index.vue?macro=true";
import { default as meus_45cupons8eI5ipaD8lMeta } from "/builds/trevo/web/pages/perfil/meus-cupons.vue?macro=true";
import { default as politicas_45de_45privacidadeCzyn587AijMeta } from "/builds/trevo/web/pages/politicas-de-privacidade.vue?macro=true";
import { default as quem_45somos3HGjHZ0MANMeta } from "/builds/trevo/web/pages/quem-somos.vue?macro=true";
import { default as termos_45e_45condicoesQ8XXM7SH7NMeta } from "/builds/trevo/web/pages/termos-e-condicoes.vue?macro=true";
import { default as component_45stubPIqMhsAxMAMeta } from "/builds/trevo/web/node_modules/.pnpm/nuxt@3.13.2_@parcel+watcher@2.4.1_@types+node@22.7.4_eslint@9.11.1_jiti@2.0.0__ioredis@5.4.1__lfhx5gp2j77ibsanr2c6iqulfa/node_modules/nuxt/dist/pages/runtime/component-stub.js?macro=true";
import { default as component_45stubPIqMhsAxMA } from "/builds/trevo/web/node_modules/.pnpm/nuxt@3.13.2_@parcel+watcher@2.4.1_@types+node@22.7.4_eslint@9.11.1_jiti@2.0.0__ioredis@5.4.1__lfhx5gp2j77ibsanr2c6iqulfa/node_modules/nuxt/dist/pages/runtime/component-stub.js";
export default [
  {
    name: "location-categorias-categoria",
    path: "/:location()/categorias/:categoria()",
    component: () => import("/builds/trevo/web/pages/[location]/categorias/[categoria].vue")
  },
  {
    name: "location-exames-slug",
    path: "/:location()/exames/:slug()",
    component: () => import("/builds/trevo/web/pages/[location]/exames/[slug].vue")
  },
  {
    name: "location-exames",
    path: "/:location()/exames",
    component: () => import("/builds/trevo/web/pages/[location]/exames/index.vue")
  },
  {
    name: "location",
    path: "/:location()",
    component: () => import("/builds/trevo/web/pages/[location]/index.vue")
  },
  {
    name: "checkout-confirmacao-de-agendamento",
    path: "/checkout/confirmacao-de-agendamento",
    meta: confirmacao_45de_45agendamentoR0DT4cNrOSMeta || {},
    component: () => import("/builds/trevo/web/pages/checkout/confirmacao-de-agendamento.vue")
  },
  {
    name: "checkout-endereco-de-cobranca",
    path: "/checkout/endereco-de-cobranca",
    meta: endereco_45de_45cobrancaW14lAxbet3Meta || {},
    component: () => import("/builds/trevo/web/pages/checkout/endereco-de-cobranca.vue")
  },
  {
    name: "checkout-forma-de-pagamento",
    path: "/checkout/forma-de-pagamento",
    meta: forma_45de_45pagamentovClpeXTunkMeta || {},
    component: () => import("/builds/trevo/web/pages/checkout/forma-de-pagamento.vue")
  },
  {
    name: "checkout",
    path: "/checkout",
    meta: indexko2mxm5Ey8Meta || {},
    component: () => import("/builds/trevo/web/pages/checkout/index.vue")
  },
  {
    name: "checkout-pagamento-aprovado",
    path: "/checkout/pagamento-aprovado",
    meta: pagamento_45aprovadojSDq6TsWmfMeta || {},
    component: () => import("/builds/trevo/web/pages/checkout/pagamento-aprovado.vue")
  },
  {
    name: "checkout-pagamento",
    path: "/checkout/pagamento",
    meta: pagamentoV8B2PDPkknMeta || {},
    component: () => import("/builds/trevo/web/pages/checkout/pagamento.vue")
  },
  {
    name: "checkout-pedido-medico",
    path: "/checkout/pedido-medico",
    meta: pedido_45medicoB3HrMkLrKtMeta || {},
    component: () => import("/builds/trevo/web/pages/checkout/pedido-medico.vue")
  },
  {
    name: "checkout-pedido-realizado",
    path: "/checkout/pedido-realizado",
    meta: pedido_45realizado6Z8XoGmNcUMeta || {},
    component: () => import("/builds/trevo/web/pages/checkout/pedido-realizado.vue")
  },
  {
    name: "checkout-processando-pagamento",
    path: "/checkout/processando-pagamento",
    meta: processando_45pagamentorQJ1m4YK0WMeta || {},
    component: () => import("/builds/trevo/web/pages/checkout/processando-pagamento.vue")
  },
  {
    name: "checkout-qr-code",
    path: "/checkout/qr-code",
    meta: qr_45codePDwCvr7HzeMeta || {},
    component: () => import("/builds/trevo/web/pages/checkout/qr-code.vue")
  },
  {
    name: "checkout-revisao-de-dados",
    path: "/checkout/revisao-de-dados",
    meta: revisao_45de_45dadosRRyuXl2sFJMeta || {},
    component: () => import("/builds/trevo/web/pages/checkout/revisao-de-dados.vue")
  },
  {
    name: "compra-garantida",
    path: "/compra-garantida",
    component: () => import("/builds/trevo/web/pages/compra-garantida.vue")
  },
  {
    name: "faq",
    path: "/faq",
    meta: faqarItF7PKASMeta || {},
    component: () => import("/builds/trevo/web/pages/faq.vue")
  },
  {
    name: "index",
    path: "/",
    component: () => import("/builds/trevo/web/pages/index.vue")
  },
  {
    name: "pagamento-protegido",
    path: "/pagamento-protegido",
    component: () => import("/builds/trevo/web/pages/pagamento-protegido.vue")
  },
  {
    name: "perfil-dados-cadastrais",
    path: "/perfil/dados-cadastrais",
    meta: dados_45cadastraisaCdUv7QsfCMeta || {},
    component: () => import("/builds/trevo/web/pages/perfil/dados-cadastrais.vue")
  },
  {
    name: "perfil-historico-de-exames",
    path: "/perfil/historico-de-exames",
    meta: historico_45de_45examesnvIiVUA5n8Meta || {},
    component: () => import("/builds/trevo/web/pages/perfil/historico-de-exames.vue")
  },
  {
    name: "perfil",
    path: "/perfil",
    meta: indexB170U3CwOUMeta || {},
    component: () => import("/builds/trevo/web/pages/perfil/index.vue")
  },
  {
    name: "perfil-meus-cupons",
    path: "/perfil/meus-cupons",
    meta: meus_45cupons8eI5ipaD8lMeta || {},
    component: () => import("/builds/trevo/web/pages/perfil/meus-cupons.vue")
  },
  {
    name: "politicas-de-privacidade",
    path: "/politicas-de-privacidade",
    meta: politicas_45de_45privacidadeCzyn587AijMeta || {},
    component: () => import("/builds/trevo/web/pages/politicas-de-privacidade.vue")
  },
  {
    name: "quem-somos",
    path: "/quem-somos",
    component: () => import("/builds/trevo/web/pages/quem-somos.vue")
  },
  {
    name: "termos-e-condicoes",
    path: "/termos-e-condicoes",
    meta: termos_45e_45condicoesQ8XXM7SH7NMeta || {},
    component: () => import("/builds/trevo/web/pages/termos-e-condicoes.vue")
  },
  {
    name: component_45stubPIqMhsAxMAMeta?.name,
    path: "/laboratorio",
    component: component_45stubPIqMhsAxMA
  },
  {
    name: component_45stubPIqMhsAxMAMeta?.name,
    path: "/unidades",
    component: component_45stubPIqMhsAxMA
  },
  {
    name: component_45stubPIqMhsAxMAMeta?.name,
    path: "/entrar",
    component: component_45stubPIqMhsAxMA
  },
  {
    name: component_45stubPIqMhsAxMAMeta?.name,
    path: "/cadastrar",
    component: component_45stubPIqMhsAxMA
  },
  {
    name: component_45stubPIqMhsAxMAMeta?.name,
    path: "/checkout/entrar",
    component: component_45stubPIqMhsAxMA
  },
  {
    name: component_45stubPIqMhsAxMAMeta?.name,
    path: "/sitemap.xml",
    component: component_45stubPIqMhsAxMA
  },
  {
    name: component_45stubPIqMhsAxMAMeta?.name,
    path: "/pages-sitemap.xml",
    component: component_45stubPIqMhsAxMA
  },
  {
    name: component_45stubPIqMhsAxMAMeta?.name,
    path: "/sao-paulo-sp-sitemap.xml",
    component: component_45stubPIqMhsAxMA
  },
  {
    name: component_45stubPIqMhsAxMAMeta?.name,
    path: "/osasco-sp-sitemap.xml",
    component: component_45stubPIqMhsAxMA
  },
  {
    name: component_45stubPIqMhsAxMAMeta?.name,
    path: "/categorias-sp-sitemap.xml",
    component: component_45stubPIqMhsAxMA
  },
  {
    name: component_45stubPIqMhsAxMAMeta?.name,
    path: "/categorias-osasco-sitemap.xml",
    component: component_45stubPIqMhsAxMA
  }
]