<script lang="ts" setup>
defineProps({
  noRedirectToHome: {
    type: Boolean,
    default: false,
  },
})

function handleClose() {
  useCart().showApplyCouponDialog.value = false

  return navigateTo('/')
}
</script>

<template>
  <Dialog :show="useCart().showApplyCouponDialog.value">
    <div class="bg-white flex flex-col items-center rounded-lg xl:max-w-[530px] overflow-x-hidden">
      <div class="bg-gradient-to-r from-success-50 to-[#0D7F3B] w-full rounded-t-lg relative">
        <div class="bg-[url('https://web-cdn.saudetrevo.com.br/illustrations/banner-tickets.svg')]">
          <h2 class="flex flex-col items-center text-white font-bold text-3xl leading-9 py-2">
            <span> Cupom </span>
            <span> adicionado </span>
          </h2>

          <Icon
            name="mdi:close" class="text-white absolute right-[30px] xl:right-[94px] top-11 cursor-pointer"
            @click="useCart().showApplyCouponDialog.value = false"
          />
        </div>
      </div>

      <div class="px-[70px] pb-14 flex flex-col items-center">
        <p class="text-center mb-[133px] mt-[111px] text-neutral-30 text-base leading-6">
          O cupom foi adicionado ao seu carrinho com sucesso e aparecerá automaticamente ao final da compra na
          <strong>Forma de Pagamento.</strong>
        </p>

        <Button class="w-[296px]" @click="handleClose">
          Buscar exames
        </Button>
      </div>
    </div>
  </Dialog>
</template>
