export enum AuthSendTokenError {
  INVALID_PHONE = 'E10004',
  CANNOT_REQUEST_VERIFICATION_CODE = 'E10009',
  BLOCKED_FROM_REQUESTING_CODE = 'E10010',
  UNPROCESSABLE_ENTITY = 'unprocessable_entity',
}

export interface AuthSendTokenErrorInfo {
  message: string
  formFields: string[]
}

export type AuthSendTokenErrorMessageType = {
  [key in AuthSendTokenError]: AuthSendTokenErrorInfo
}

export const AuthSendTokenErrorMessage: AuthSendTokenErrorMessageType = {
  [AuthSendTokenError.INVALID_PHONE]: {
    message: 'Telefone inválido.',
    formFields: ['phone'],
  },
  [AuthSendTokenError.CANNOT_REQUEST_VERIFICATION_CODE]: {
    message: 'Código foi enviado recentemente e não pode ser enviado de novo.',
    formFields: ['phone'],
  },
  [AuthSendTokenError.BLOCKED_FROM_REQUESTING_CODE]: {
    message: 'Você foi bloqueado por muitas tentativas. Por favor, aguarde alguns minutos e tente novamente.',
    formFields: ['phone'],
  },
  [AuthSendTokenError.UNPROCESSABLE_ENTITY]: {
    message: 'Formato de dados incorreto. Por favor, verifique as informações e tente novamente.',
    formFields: ['phone'],
  },
}
