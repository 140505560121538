import type { RouteLocationNormalized } from '#vue-router'
import { LocalStorageKey } from '@/modules/core/enums'
import { isJWT } from '@/validators/helpers'
import { jwtDecode } from 'jwt-decode'

interface IAccessTokenDecoded {
  id: string
  email: string
  name: string
  last_name: string
  phone: string
  tax_id: string
  role_id: string
  ab_group: string
  exp: number
}

interface IRefreshTokenDecoded {
  id: string
  exp: number
}

const PROTECTED_ROUTES = [
  'checkout-endereco-de-cobranca',
  'checkout-forma-de-pagamento',
  'checkout-pagamento-aprovado',
  'checkout-pedido-medico',
  'checkout-pedido-realizado',
  'checkout-processando-pagamento',
  'checkout-qr-code',
  'checkout-revisão-de-dados',
  'perfil',
  'perfil-historico-de-exames',
  'perfil-meus-cupons',
  'perfil-dados-cadastrais',
]

function clean(to: RouteLocationNormalized, from: RouteLocationNormalized) {
  localStorage.removeItem(LocalStorageKey.AccessToken)
  localStorage.removeItem(LocalStorageKey.RefreshToken)

  const goingToAuthenticatedPage = PROTECTED_ROUTES.includes(to.name as string)

  if (!goingToAuthenticatedPage)
    return

  useAuth().showSignInModal.value = true

  return from.name ? abortNavigation() : navigateTo('/')
}

function hasAllKeys(accessTokenDecoded: object) {
  const CURRENT_KEYS: Array<string> = ['id', 'email', 'name', 'last_name', 'phone', 'tax_id', 'role_id', 'ab_group', 'exp']

  const keys = Object.keys(accessTokenDecoded)

  CURRENT_KEYS
    .forEach((key: string) => {
      if (!keys.includes(key))
        return false
    })

  return true
}

export default defineNuxtRouteMiddleware(async (to: RouteLocationNormalized, from: RouteLocationNormalized) => {
  if (import.meta.client) {
    let accessToken = (to.query?.token || localStorage.getItem(LocalStorageKey.AccessToken)) as string
    let refreshToken = localStorage.getItem(LocalStorageKey.RefreshToken)

    if (!accessToken || !isJWT(accessToken))
      return clean(to, from)

    const accessTokenDecoded = jwtDecode(accessToken) as IAccessTokenDecoded

    if (accessTokenDecoded.exp * 1000 < Date.now() || !hasAllKeys(accessTokenDecoded)) {
      if (!refreshToken || !isJWT(refreshToken))
        return clean(to, from)

      const refreshTokenDecoded = jwtDecode(refreshToken) as IRefreshTokenDecoded

      if (refreshTokenDecoded.exp * 1000 < Date.now())
        return clean(to, from)

      const response = await getNewToken()

      if (!response)
        return clean(to, from)

      accessToken = response.accessToken
      refreshToken = response.refreshToken
    }

    useLocalStorage(LocalStorageKey.AccessToken, '').value = accessToken

    if (refreshToken)
      useLocalStorage(LocalStorageKey.RefreshToken, '').value = refreshToken
  }
})
