import { LocalStorageKey } from '@/modules/core/enums'
import * as Sentry from '@sentry/vue'

type TClickIdentifier = 'gclid' | 'wbraid' | 'gbraid'
interface IClickIdentifierData extends Record<TClickIdentifier, string> {
  expires: string
}
export default () => {
  function apply(key: TClickIdentifier) {
    try {
      const today = new Date()

      if (useRoute()?.query?.[key]) {
        const data = {
          [key]: useRoute().query[key] as string,
          expires: new Date(today.setDate(today.getDate() + 90)).toDateString(),
        }

        localStorage.setItem(key, JSON.stringify(data))

        return
      }

      const clickIdentifier = localStorage.getItem(key)

      if (!clickIdentifier?.includes('{'))
        return localStorage.removeItem(key)

      const data: IClickIdentifierData = JSON.parse(clickIdentifier)

      if (!data || !data.expires || !data[key]) {
        localStorage.removeItem(key)

        throw new Error(`${key} data is invalid: ${clickIdentifier}`)
      }

      if (new Date(data.expires) < today)
        return localStorage.removeItem(key)
    }
    catch (error) {
      Sentry.captureException(error)
    }
  }

  [
    LocalStorageKey.GCLID as TClickIdentifier,
    LocalStorageKey.WBRAID as TClickIdentifier,
    LocalStorageKey.GBRAID as TClickIdentifier,
  ].forEach(apply)
}
