import revive_payload_client_uvloVoprYn from "/builds/trevo/web/node_modules/.pnpm/nuxt@3.13.2_@parcel+watcher@2.4.1_@types+node@22.7.4_eslint@9.11.1_jiti@2.0.0__ioredis@5.4.1__lfhx5gp2j77ibsanr2c6iqulfa/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_4xZs0MmWIF from "/builds/trevo/web/node_modules/.pnpm/nuxt@3.13.2_@parcel+watcher@2.4.1_@types+node@22.7.4_eslint@9.11.1_jiti@2.0.0__ioredis@5.4.1__lfhx5gp2j77ibsanr2c6iqulfa/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_Cc6B6hDr3X from "/builds/trevo/web/node_modules/.pnpm/nuxt@3.13.2_@parcel+watcher@2.4.1_@types+node@22.7.4_eslint@9.11.1_jiti@2.0.0__ioredis@5.4.1__lfhx5gp2j77ibsanr2c6iqulfa/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import _0_siteConfig_SIo1Qgswzi from "/builds/trevo/web/node_modules/.pnpm/nuxt-site-config@2.2.18_magicast@0.3.5_rollup@4.23.0_vite@5.4.8_@types+node@22.7.4_terser@5.3_ha7r7qiljj7ciulbclhar27q5m/node_modules/nuxt-site-config/dist/runtime/nuxt/plugins/0.siteConfig.js";
import payload_client_iomxRTPIAX from "/builds/trevo/web/node_modules/.pnpm/nuxt@3.13.2_@parcel+watcher@2.4.1_@types+node@22.7.4_eslint@9.11.1_jiti@2.0.0__ioredis@5.4.1__lfhx5gp2j77ibsanr2c6iqulfa/node_modules/nuxt/dist/app/plugins/payload.client.js";
import navigation_repaint_client_A8e5GPOtbT from "/builds/trevo/web/node_modules/.pnpm/nuxt@3.13.2_@parcel+watcher@2.4.1_@types+node@22.7.4_eslint@9.11.1_jiti@2.0.0__ioredis@5.4.1__lfhx5gp2j77ibsanr2c6iqulfa/node_modules/nuxt/dist/app/plugins/navigation-repaint.client.js";
import check_outdated_build_client_WrLmjkjcZS from "/builds/trevo/web/node_modules/.pnpm/nuxt@3.13.2_@parcel+watcher@2.4.1_@types+node@22.7.4_eslint@9.11.1_jiti@2.0.0__ioredis@5.4.1__lfhx5gp2j77ibsanr2c6iqulfa/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import chunk_reload_client_GOMK2RqK5O from "/builds/trevo/web/node_modules/.pnpm/nuxt@3.13.2_@parcel+watcher@2.4.1_@types+node@22.7.4_eslint@9.11.1_jiti@2.0.0__ioredis@5.4.1__lfhx5gp2j77ibsanr2c6iqulfa/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import components_plugin_KR1HBZs4kY from "/builds/trevo/web/.nuxt/components.plugin.mjs";
import prefetch_client_hosRaci2So from "/builds/trevo/web/node_modules/.pnpm/nuxt@3.13.2_@parcel+watcher@2.4.1_@types+node@22.7.4_eslint@9.11.1_jiti@2.0.0__ioredis@5.4.1__lfhx5gp2j77ibsanr2c6iqulfa/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import plugin_client_PqY09u8AL2 from "/builds/trevo/web/node_modules/.pnpm/nuxt-gtag@3.0.1_magicast@0.3.5_rollup@4.23.0_webpack-sources@3.2.3/node_modules/nuxt-gtag/dist/runtime/plugin.client.js";
import plugin_kgH4GAEPA5 from "/builds/trevo/web/node_modules/.pnpm/@nuxt+icon@1.5.2_magicast@0.3.5_rollup@4.23.0_vite@5.4.8_@types+node@22.7.4_terser@5.34.1__vu_qiex6eniv5qrei4aw54xqbmeie/node_modules/@nuxt/icon/dist/runtime/plugin.js";
import api_GFfDXud5Cr from "/builds/trevo/web/plugins/api.ts";
import error_handler_kEP5FliEXj from "/builds/trevo/web/plugins/error-handler.ts";
import filters_VNgPjx6zHj from "/builds/trevo/web/plugins/filters.ts";
import maska_UHaKf2z1iQ from "/builds/trevo/web/plugins/maska.ts";
import sentry_client_shVUlIjFLk from "/builds/trevo/web/plugins/sentry.client.ts";
import toastify_ScRgZgP9op from "/builds/trevo/web/plugins/toastify.ts";
import validators_kQs43V5Huh from "/builds/trevo/web/plugins/validators.ts";
export default [
  revive_payload_client_uvloVoprYn,
  unhead_4xZs0MmWIF,
  router_Cc6B6hDr3X,
  _0_siteConfig_SIo1Qgswzi,
  payload_client_iomxRTPIAX,
  navigation_repaint_client_A8e5GPOtbT,
  check_outdated_build_client_WrLmjkjcZS,
  chunk_reload_client_GOMK2RqK5O,
  components_plugin_KR1HBZs4kY,
  prefetch_client_hosRaci2So,
  plugin_client_PqY09u8AL2,
  plugin_kgH4GAEPA5,
  api_GFfDXud5Cr,
  error_handler_kEP5FliEXj,
  filters_VNgPjx6zHj,
  maska_UHaKf2z1iQ,
  sentry_client_shVUlIjFLk,
  toastify_ScRgZgP9op,
  validators_kQs43V5Huh
]