<script setup lang="ts">
import { PromoErrorMessage } from '@/modules/cart/types/promo.enum'

import { useVuelidate } from '@vuelidate/core'

const form = reactive({
  promoSearch: '',
})

const { $validators } = useNuxtApp()

const selectedPromo = ref<string>('')

const promoCodeApplyed = computed(() => !!form.promoSearch || !!selectedPromo.value)

const errors = ref<Record<string, string | undefined>>({})

const rules = computed(() => ({
  promoSearch: {
    minLength: $validators.size({ size: 3 }),
    serverError: $validators.serverError(errors, 'promoSearch'),
  },
}))

const { coupons, loading } = useCart()

const validation = useVuelidate(rules, form)

const { cart } = useCart()

async function couponIsValid(code: string) {
  try {
    await $fetch('/v1/recommendations/promo', {
      baseURL: useRuntimeConfig().public.api.awsGateway,
      method: 'POST',
      body: {
        customer_id: useCustomer().customer.value.id,
        bundle: [],
        code,
      },
    })

    return true
  }
  catch (error) {
    handleApiErrors(error, errors, PromoErrorMessage, 'Cupom inválido.')

    validation.value.promoSearch.$touch()

    return false
  }
}

async function applyPromo() {
  loading.value = true

  const promoCodeUpperCase = form.promoSearch.length > 3
    ? form.promoSearch.toUpperCase()
    : selectedPromo.value?.toUpperCase()

  const isValid = await couponIsValid(promoCodeUpperCase)

  if (isValid) {
    cart.value = { ...cart.value, coupon: promoCodeUpperCase }

    usePromoModalClose()
  }

  loading.value = false
}

watch(selectedPromo, (code: string) => {
  if (code) {
    form.promoSearch = ''
    validation.value.$reset()
  }
})

watch(
  () => form.promoSearch,
  () => {
    if (form.promoSearch.length)
      selectedPromo.value = ''
  },
  { immediate: true },
)

onNuxtReady(() => {
  selectedPromo.value = cart.value.coupon || ''
  validation.value.$reset()
})

function clearSelectedPromo() {
  form.promoSearch = form.promoSearch.toUpperCase()

  validation.value.$reset()

  selectedPromo.value = ''
}
</script>

<template>
  <div class="flex flex-col justify-start gap-3 px-4 w-full h-full relative">
    <p v-if="coupons" class="font-extrabold text-xl text-primary-50 text-center">
      Escolha e aproveite!
    </p>

    <form id="promo">
      <div v-if="coupons" class="flex flex-col xl:flex-wrap gap-3 my-3 xl:flex-row w-full overflow-y-auto max-h-[150px]">
        <CupomBadge
          v-for="(promo, indexPromo) in coupons"
          :key="`promo-${indexPromo}`"
          v-model="selectedPromo"
          :cupom="promo"
          :unchecked="!!form.promoSearch.length"
        />
      </div>

      <TInputText
        id="promo-search"
        v-model="form.promoSearch"
        label="Inserir cupom de desconto"
        :max="35"
        :validation="validation.promoSearch"
        @change="clearSelectedPromo"
        @enter="applyPromo"
      />

      <ul class="list-disc list-inside text-sm text-neutral-30 mt-3">
        <li>Limitado a 1 cupom por compra</li>
        <li>Desconto não cumulativo com outras promoções</li>
      </ul>

      <div class="flex items-center gap-4 flex-col px-9 py-4 border-t mt-4 border-neutral-90">
        <p class="text-xs text-center text-neutral-variant-30">
          Desconto sujeito a alteração sem aviso prévio.
        </p>

        <Button
          :disabled="!promoCodeApplyed"
          :loading="loading"
          full
          @click.prevent="applyPromo"
        >
          Usar Cupom
        </Button>
      </div>
    </form>
  </div>
</template>
