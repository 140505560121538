import { AuthSendTokenErrorMessage } from '@/modules/customer/types/auth.enum'
import useAuth from './useAuth'

interface ISendTokenPayload {
  phone: string
  sessionId: string
}

export default async (payload: ISendTokenPayload) => {
  const { loading, errors } = useAuth()

  try {
    loading.value = true

    const baseURL = useRuntimeConfig().public.api.awsGateway
    const path = `/v2/customer/users/request_phone_code`

    await $fetch(path, { baseURL, method: 'POST', body: JSON.stringify(camelToSnake(payload)) })
  }
  catch (error: any) {
    const { $toast } = useNuxtApp()

    handleApiErrors(
      error,
      errors,
      AuthSendTokenErrorMessage,
      'Falha ao enviar código de verificação SMS.',
    )

    if (errors.value.form) {
      $toast.error(errors.value.form)
    }
    else {
      $toast.error('Falha ao enviar código de verificação SMS.')
    }

    throw error
  }
  finally {
    loading.value = false
  }
}
