import type { ILocation, ILocationParams } from '../types/location.d'
import { SearchProcedureOrdenation, SearchProcedureOrdenationMapping } from '@/modules/search/types/enums'
import { EventName } from '@/modules/trevo/types/enum'
import { isCoords, isLocationSlug, isZipCode } from '@/validators/helpers'
import { LocationType } from '../types/enums'

const path = '/api/location'

export function useLocationParam() {
  return useState<string>('LocationParam', () => 'sao-paulo-sp')
}

export function useLocationState() {
  return useState<ILocation | null>('LocationState', () => null)
}

export function useLocationParamObject() {
  return computed<ILocationParams>(() => {
    if (isLocationSlug(useLocationParam().value)) {
      return {
        type: LocationType.CITY,
        city_slug: useLocationParam().value,
      }
    }
    else if (isZipCode(useLocationParam().value)) {
      return {
        type: LocationType.ZIP_CODE,
        zipcode: useLocationParam().value,
      }
    }
    else if (isCoords(useLocationParam().value)) {
      return {
        type: LocationType.GEOLOCATION,
        lat: useLocationParam().value.split(',')[0],
        lng: useLocationParam().value.split(',')[1],
      }
    }
    else {
      useLocationParam().value = 'sao-paulo-sp'

      return {
        type: LocationType.CITY,
        city_slug: 'sao-paulo-sp',
      }
    }
  })
}

interface IUseLocation {
  data: ComputedRef<ILocation | null>
  error: ComputedRef<any>
}

export default function (): IUseLocation {
  const fetched = useFetch<ILocation>(path, { query: useLocationParamObject(), key: 'UseLocation', dedupe: 'defer', transform: snakeToCamel })

  useLocationState().value = fetched.data.value

  onNuxtReady(async () => {
    const key = 'location'

    watch(useLocationParam(), (newLocation) => {
      sessionStorage.setItem(key, newLocation)
    })

    watch(() => useLocationState().value, () => {
      track(EventName.ChangeLocation, {
        citySlug: useLocationState().value?.slug,
        postalCode: useLocationState().value?.postalCode,
      })
    }, { deep: true })

    const { params } = useRoute()
    if (!params.location) {
      const locationFromStorage = sessionStorage.getItem(key)

      if (locationFromStorage && locationFromStorage !== useLocationParam().value)
        useLocationParam().value = locationFromStorage
    }
  })

  if (useLocationParam().value) {
    const router = useRouter()

    watch(useLocationParam(), (newLocationData) => {
      const { fullPath, params } = useRoute()

      if (!newLocationData)
        return

      if (!params.location || params.location === newLocationData)
        return

      const newPath = fullPath.replace(params.location as string, useLocationParam().value)
      router.options.history.replace(newPath)
    }, { immediate: true })

    watch(useLocationParamObject(), (newLocationParams) => {
      if (newLocationParams.type !== LocationType.CITY)
        useSearchProcedureOrdenation().value = SearchProcedureOrdenationMapping[SearchProcedureOrdenation.DISTANCE]
    }, { immediate: true })
  }

  return {
    data: computed(() => fetched.data.value),
    error: computed(() => fetched.error.value),
  }
}

export const useLocationModalActiveState = () => useState('UseLocationModalActiveState', () => false)

export function useLocationModalOpen(): void {
  useGeolocationPopupClose()

  useLocationModalActiveState().value = true
}

export function useLocationModalClose(): void {
  useLocationModalActiveState().value = false
}
