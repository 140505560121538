import type { IProcedureResponse, IUseProcedures } from '../types/procedure'
import { EventName } from '@/modules/trevo/types/enum'

export const useProcedureSlug = () => useState<string[]>('slugs', () => [])

export interface IOCRResponse {
  procedures: Array<{
    procedure_id: string
    procedure_name: string
    display_name: string
    display_slug: string
    tuss_code: string
    score: number
  }>
  physician: {
    name: string
    crm: string
  }
  patient: {
    name: string
    tax_id: string
  }
  file_hash: string
}

export default function (): IUseProcedures {
  const path = '/api/procedure/display'

  const { data, error, pending } = useFetch<IProcedureResponse[]>(path, {
    method: 'POST',
    key: 'UseProcedures',
    body: { procedures: useProcedureSlug() },
    transform: snakeToCamel,
    dedupe: 'defer',
  })

  function addSlug(slug: string | string[]) {
    const oldSlugs = useProcedureSlug().value

    if (Array.isArray(slug))
      useProcedureSlug().value = uniq([...oldSlugs, ...slug]).map((e: string) => e.replace(/_DESKTOP/g, ''))

    else if (!oldSlugs.includes(slug))
      useProcedureSlug().value = [...oldSlugs, slug].map(e => e.replace(/_DESKTOP/g, ''))
  }

  async function submitOCR(file: File): Promise<IOCRResponse> {
    const baseURL = useRuntimeConfig().public.api.awsGateway
    const formData = new FormData()
    formData.append('file', file)

    const response = await $fetch<IOCRResponse>('/v1/ocr/submit', {
      baseURL,
      method: 'POST',
      body: formData,
    })

    if (response.procedures?.length) {
      track(EventName.OCRUpload, {
        procedures: response.procedures?.length,
      })
      addSlug(response.procedures.map(proc => proc.display_slug))
    }

    return response
  }

  async function getPrescriptionIds(customerId: string, prescriptionHashes: string[]): Promise<string[]> {
    const baseURL = useRuntimeConfig().public.api.awsGateway
    return await $fetch<string[]>('/v1/sales-order/orders/associate-prescriptions', {
      baseURL,
      method: 'POST',
      body: {
        customer_id: customerId,
        prescription_hashes: prescriptionHashes,
      },
    })
  }

  return {
    addSlug,
    removeSlug(slug: string) {
      const oldSlugs = useProcedureSlug().value

      useProcedureSlug().value = oldSlugs.filter(s => s !== slug)
    },
    pending,
    procedures: computed(() => data.value || []),
    error: computed(() => error.value || []),
    submitOCR,
    getPrescriptionIds,
  }
}
