<script setup lang="ts">
import { LocalStorageKey } from '@/modules/core/enums'
import { EventName } from '@/modules/trevo/types/enum'
import * as Sentry from '@sentry/vue'

if (import.meta.client) {
  useAuthGlobally()
  useCustomerGlobally()
  useCartGlobally()
  abTest()
}

const phone = ref('')
const showConfirmationModal = ref(false)
const scheduling = ref({})
const gclid = useLocalStorage(LocalStorageKey.GCLID, '')
const accessToken = useLocalStorage(LocalStorageKey.AccessToken, '')

const { showSignInModal, showSignUpModal, showTokenModal, logout } = useAuth()

const fullPageLoading = useFullPageLoading()

function handleShowTokenModal(currentPhone: string) {
  phone.value = currentPhone

  showTokenModal.value = true
  showSignInModal.value = false
}

const { customer } = useCustomer()
watch(
  [
    customer,
  ],
  () => {
    try {
      const baseURL = useRuntimeConfig().public.api.awsGateway

      if (!customer.value?.id || !gclid.value)
        return

      return $fetch(`/v2/customer/users/${customer.value.id}/device`, {
        baseURL,
        method: 'POST',
        body: JSON.stringify({
          gclid: getClickIdentifier(LocalStorageKey.GCLID),
          gbraid: getClickIdentifier(LocalStorageKey.GBRAID),
          wbraid: getClickIdentifier(LocalStorageKey.WBRAID),
          device_id: useTrackMixpanelDistinctId().value,
          session_id: useTrackGoogleSessionId().value,
          user_pseudo_id: useTrackGoogleClientId().value,
        }),
        headers: {
          Authtoken: accessToken.value,
        },
      })
    }
    catch (error) {
      Sentry.captureException(error)
    }
  },
  {
    deep: true,
  },
)

function closeModals() {
  logout()
  showSignInModal.value = false
  showTokenModal.value = false
  showSignUpModal.value = false
  showConfirmationModal.value = false
}

const currentModalComponent = computed(() => {
  if (showSignInModal.value)
    return resolveComponent('CustomerSignInModal')
  if (showSignUpModal.value)
    return resolveComponent('CustomerSignUpModal')
  if (showTokenModal.value)
    return resolveComponent('CustomerTokenModal')
  return resolveComponent('ProfileProcedureSchedulingConfirmationModal')
})

onNuxtReady(() => {
  setClickIdentifiers()

  track(EventName.PageView)
})
</script>

<template>
  <div>
    <NuxtLayout>
      <NuxtPage />

      <TFullPageLoading v-if="fullPageLoading" />

      <CartPromoSideSheet :show-promo="usePromoModalActiveState().value" @close="usePromoModalClose" />

      <ProfilePromoAdded />

      <Dialog
        :show="showSignInModal || showSignUpModal || showTokenModal || showConfirmationModal"
        @close="closeModals"
      >
        <transition name="fade" mode="out-in">
          <component
            :is="currentModalComponent"
            :phone="phone"
            :scheduling="scheduling"
            @close="closeModals"
            @send-token="handleShowTokenModal"
          />
        </transition>
      </Dialog>
    </NuxtLayout>

    <CoreWhatsAppButton />
  </div>
</template>
