import { OrderStatus } from './enums'

export const BREAKPOINTS = {
  'sm': 640,
  'md': 768,
  'lg': 1024,
  'xl': 1280,
  '2xl': 1536,
}

export const SalesOrderStatus = {
  [OrderStatus.Pending]: 'Aguardando Pagamento',
  [OrderStatus.Waiting_For_Payment]: 'Aguardando Pagamento',
  [OrderStatus.Denied]: 'Pagamento Recusado',
  [OrderStatus.Canceled]: 'Procedimento cancelado',
  [OrderStatus.Approved]: 'Aguardando confirmação do laboratório',
  [OrderStatus.Ready]: 'Liberado para realização',
  [OrderStatus.Finished]: 'Exame realizado',
  [OrderStatus.Refunded]: 'Reembolso finalizado',
  [OrderStatus.Expired]: 'Expirado',
  [OrderStatus.Pending_But_Authorized]: 'Liberado para realização',
  [OrderStatus.Waiting_For_Payment]: 'Agendamento confirmado',
  [OrderStatus.Waiting_For_Refund]: 'Aguardando reembolso',
  [OrderStatus.Waiting_For_Results]: 'Aguardando resultado de exame(s)',
  [OrderStatus.Schedule_Confirmation]: 'Aguardando confirmação do paciente',
}

export const SalesOrderStatusColors = {
  [OrderStatus.Pending]: 'text-tertiary-30',
  [OrderStatus.Waiting_For_Payment]: 'text-tertiary-30',
  [OrderStatus.Denied]: 'text-error-50',
  [OrderStatus.Canceled]: 'text-neutral-30',
  [OrderStatus.Approved]: 'text-tertiary-30',
  [OrderStatus.Ready]: 'text-success-50',
  [OrderStatus.Finished]: 'text-information-50',
  [OrderStatus.Refunded]: 'text-neutral-30',
  [OrderStatus.Expired]: 'text-neutral-30',
  [OrderStatus.Pending_But_Authorized]: 'text-success-50',
  [OrderStatus.Waiting_For_Payment]: 'text-information-50',
  [OrderStatus.Waiting_For_Refund]: 'text-neutral-30',
  [OrderStatus.Waiting_For_Results]: 'text-information-30',
  [OrderStatus.Schedule_Confirmation]: 'text-tertiary-30',
}

export const cards: any = {
  Mastercard: 'https://web-cdn.saudetrevo.com.br/icons/mastercard.svg',
  Amex: 'https://web-cdn.saudetrevo.com.br/icons/amex.svg',
  Dinners: 'https://web-cdn.saudetrevo.com.br/icons/dinners.svg',
  Elo: 'https://web-cdn.saudetrevo.com.br/icons/elo.svg',
  Hiper: 'https://web-cdn.saudetrevo.com.br/icons/hiper.svg',
  Visa: 'https://web-cdn.saudetrevo.com.br/icons/hiper.svg',
}

export const whatsAppLink
  = 'https://api.whatsapp.com/send?phone=5511957756341&text=Oi%20Sa%C3%BAde%20Trevo.%20Gostaria%20de%20realizar%20um%20or%C3%A7amento%20de%20exame%20com%20voc%C3%AAs.%20Podem%20me%20ajudar?'
